import { template as template_dd1eb5c77e5b412da40d33fe49388b5b } from "@ember/template-compiler";
const FKControlMenuContainer = template_dd1eb5c77e5b412da40d33fe49388b5b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
