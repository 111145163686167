import { template as template_ff8fd58f0d7f46a7a298348a045a4b15 } from "@ember/template-compiler";
const WelcomeHeader = template_ff8fd58f0d7f46a7a298348a045a4b15(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
