import { template as template_1d867d0ffc5a46b7b0ba55b322e3159e } from "@ember/template-compiler";
const SidebarSectionMessage = template_1d867d0ffc5a46b7b0ba55b322e3159e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
